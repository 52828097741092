import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationAbout from '../assets/images/illustrations/About_sprive.png';
import imagePartnerDefaqto from '../assets/images/partners/defaqto.png';
import imagePartnerExperian from '../assets/images/partners/experian.png';
import imagePartnerIress from '../assets/images/partners/iress.png';
import imagePartnerRightmove from '../assets/images/partners/rightmove.png';
import imagePartnerTruelayer from '../assets/images/partners/truelayer.png';

const AboutPage = () => (
  <>
    <SEO title="About Sprive" description="" />
    <div className="common-page about-page">
      <HeadingSection
        h1="About Sprive"
        h2="What’s driving our team"
        image={imageIllustrationAbout}
      />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <h3 className="title-block">We believe that living debt free should be achievable for all</h3>
      <p className="paragraph-sm">
        We’re on a mission to help people live with less debt. Debt can have a significant impact on
        people’s lives and unfortunately the amount of personal debt in the UK continues to rise,
        with over £1.6 trillion being owed.
      </p>
      <p className="paragraph-sm">
        We’re on your side and we’re passionate about building technology that helps you achieve
        your goals
      </p>
      <p className="paragraph-sm bold">
        Welcome to a world of better money management. Welcome to Sprive.
      </p>

      {/* <h3 className="title-block">Our partners</h3> */}
      {/* <div className="our-partners">
        <img src={imagePartnerExperian} alt="experian" />
        <img src={imagePartnerIress} alt="iress" />
        <img src={imagePartnerRightmove} alt="rightmove" />
        <img src={imagePartnerTruelayer} alt="truelayer" />
        <img src={imagePartnerDefaqto} alt="defaqto" />
      </div> */}
    </div>
  </section>
);

export default AboutPage;
